.button-open-text {
  font-size: 1rem;
  font-weight: 400;
  color: #57527e;
}
.max-amount-button {
  padding-left: 12px;
  padding-right: 12px;
  background-color: #a4f4f9;
  text-align: center;
  color: #57527e;
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  bottom: 4px;
  right: 4px;
  z-index: 2;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.unit-text {
  font-weight: 600;
  font-size: 14px;
  margin-right: 4px;
  text-wrap: nowrap;
}
.swap-icon-container {
  padding-top: 8px;
  padding-bottom: 8px;
}
.token-inw-icon {
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.balance-value {
  color: #57527e;
  font-weight: 700;
  margin-left: 4px;
}
.balance-container {
  justify-content: space-between;
}
.balance-text {
  color: #57527e;
  font-weight: 700;
  margin-right: 8px;
}
.swap-amount-container {
  height: 80px;
  border: 1px solid #e3dff3;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  margin-top: 4px;
}
.swap-unit-container {
  border: 1px solid #e3dff3;
  border-bottom: 0px;
  padding-left: 12px;
  padding-right: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
  margin-top: 8px;
  font-size: 14px;
  color: #57527e;
  font-weight: 700;
}
.swap-amount-input {
  width: 240px !important;
  font-size: 32px !important;
  border: transparent !important;
  text-align: center;
}
.swap-amount-input::placeholder {
  font-size: 32px !important;
  text-align: center;
}
.divider-balance {
  width: 1px;
  background: #e3dff3;
  margin-left: 4px;
  margin-right: 4px;
}
.swap-option {
  align-items: center;
}
.change-swap-option-button {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: #a4f4f9;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  /* cursor: pointer; */
  transition: background-color 0.2s;
}
.change-swap-option-button:active {
  background-color: #85e9f0;
  transition: background-color 0.2s;
}
